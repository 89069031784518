<template>
  <section>
    <div class="flex w-full sticky-item justify-between items-center">
      <div class="flex justify-start items-center gap-2">
        <div class="w-56">
          <DatePickerRange ref="datepicker" name="rangePicker" :start-date="rangeDate.selected"
            :max-date="rangeDate.maxDate" :min-date="rangeDate.minDate" :preset-ranges="rangeDate.presetRanges"
            format="L" @apply="(data) => handleChangeDatePicker(data)" :loading="loading"></DatePickerRange>
        </div>
      </div>

      <div class="flex items-center gap-x-6">
        <Filters @changeFilters="changeFilters" :accessLevel="accessLevel" :selectedCommunities="selectedCommunities"
          :loading="loading" />
        <OptionsMenu :pre-select-view-mode="accessLevel" :loading="loading" :isExporting="isExporting"
          @exportData="exportData" @accessLevelChange="changeAccessLevel" />
      </div>
    </div>
    <template v-if="hasErrorApi">
      <div class="bg-white rounded-sm p-6 text-center shadow-sm">
        <p class="text-xs leading-4 text-center text-blue-800 mb-5">
          Unable to load the data, try to reload the page
        </p>
        <button @click="fetchMetrics" class="btn-primary btn-solid font-400">
          refresh
        </button>
      </div>
    </template>
    <template v-else>
      <div ref="dhData" class="grid gap-6">
        <template v-if="loading">
          <!-- loading -->
          <Loading />
        </template>
        <template v-else>
          <div class="grid grid-cols-2 gap-6">
            <div class="col-span-2 gap-4 grid grid-cols-3 gap-y-4">
              <CardTotal v-for="(single, index) in metrics.singles" :key="single.id" :title="single.title"
                :total="single.value" :status="single.status" :dhId="single.dhId" :print-view="isExporting" @refresh="
                  () => {
                    fetchMetricById(single.dhId, index, 'singles');
                  }
                " />
            </div>
            <div v-for="(chart, index) in metrics.charts" :key="chart.id" class="bg-white p-4">
              <CardMetric :chart="chart" :print-view="isExporting" @refresh="
                () => {
                  fetchMetricById(chart.dhId, index, 'charts');
                }
              " />
              <!-- Controls the print pagination -->
              <span v-if="(index > 0 && index < metrics.charts.length - 1) && index % 6 === 5"
                class="page-break"></span>
            </div>
          </div>
        </template>
      </div>
    </template>
  </section>
</template>

<script>
import moment from "moment";
import NotifyMixin from "@/mixins/NotifyMixin";
import { mapMutations, mapGetters, mapActions } from "vuex";
import Filters from "@/components/report/dashboard/Filters.vue";
import OptionsMenu from "@/components/report/dashboard/OptionsMenu.vue";
import CardMetric from "@/components/report/dashboard/CardMetric.vue";
import CardTotal from "@/components/report/dashboard/CardTotal.vue";
import Loading from "@/components/report/loading/LoadingComponent.vue";
import HomepageBottomToolbar from "@/components/layout/HomepageBottomToolbar";
import Icon from "@/components/ui/Icon";
import DatePickerRange from "@/components/ui/datepicker/DatePickerRange";
import AuthMixin from "@/components/auth/AuthMixin";
import { exportToCsv, copyCsvToClipboard, exportToPdf } from "@/components/report/common/exportContent";

export default {
  name: "Dashboard",
  mixins: [NotifyMixin, AuthMixin],
  components: {
    Filters,
    CardMetric,
    CardTotal,
    HomepageBottomToolbar,
    Icon,
    DatePickerRange,
    OptionsMenu,
    Loading
  },
  data() {
    return {
      loading: true,
      hasErrorApi: true,
      selectedCommunities: [],
      rangeDate: {
        selected: [
          moment().subtract(7, "days").format("MM/DD/YYYY"),
          moment().format("MM/DD/YYYY"),
        ],
        maxDate: moment(),
        minDate: moment().subtract(20, "years"),
        presetRanges: [
          { label: "Today", range: [moment(), moment()] },
          {
            label: "Last week",
            range: [
              moment().subtract(1, "week").startOf("week"),
              moment().subtract(1, "week").endOf("week"),
            ],
          },
          {
            label: "This month",
            range: [moment().startOf("month"), moment()],
          },
          {
            label: "Last month",
            range: [
              moment().subtract(1, "month").startOf("month"),
              moment().subtract(1, "month").endOf("month"),
            ],
          },
          {
            label: "Last 30 days",
            range: [moment().subtract(30, "days"), moment()],
          },
          {
            label: "This quarter",
            range: [moment().startOf("quarter"), moment()],
          },
          {
            label: "Last quarter",
            range: [
              moment().subtract(1, "quarter").startOf("quarter"),
              moment().subtract(1, "quarter").endOf("quarter"),
            ],
          },
        ],
      },
      metrics: {
        singles: [],
        charts: [],
      },
      accessLevel: 3,
      isCollapsed: true,
      hasError: false,
      metricsAbortController: null,
      isExporting: false,
    };
  },
  computed: {
    ...mapGetters({
      preferences: "report_dashboard/getDashboardPreferences",
    }),
  },
  methods: {
    ...mapActions({
      notifySuccess: "alerts/notifySuccess",
    }),
    ...mapMutations({ addActions: "report_dashboard/SET_PREFERENCES" }),
    isEmpty(response) {
      return response.charts?.length === 0 || response.singles?.length === 0;
    },
    displayIsEmpty() {
      this.hasErrorApi = true;
      this.notifyError("No data");
      this.resetFetchMetricsStates();
    },
    async fetchMetrics() {
      this.loading = true;
      this.hasErrorApi = false;
      if (this.metricsAbortController) {
        this.metricsAbortController.abort();
      }
      this.metricsAbortController = new AbortController();
      let params = {
        startDate: this.rangeDate.selected[0],
        endDate: this.rangeDate.selected[1],
        accessLevel: this.accessLevel,
        comunityId: this.community ? this.community.id : null,
        selectedCommunities: this.selectedCommunities,
      };
      this.storeActions(params);
      const headers = {
        "x-scope-access-level": this.accessLevel,
        "x-quext-profile": JSON.stringify(this.profile),
        "x-quext-community-ids": JSON.stringify({
          comIds: this.selectedCommunities?.length
            ? this.selectedCommunities
            : this.community
              ? [this.community]
              : this.communities,
        }),
      };
      try {
        const response = await this.$reportDataProvider.getList(
          "dh_dashboard_metrics",
          {
            startDate: this.rangeDate.selected[0],
            endDate: this.rangeDate.selected[1],
            headers,
            signal: this.metricsAbortController.signal,
          }
        );
        if (this.isEmpty(response)) {
          this.displayIsEmpty();
          return;
        }
        this.metrics = response;
        this.resetFetchMetricsStates();
      } catch (error) {
        if (error.message === "canceled") {
          return;
        }
        this.hasErrorApi = true;
        this.notifyError(error);
        this.resetFetchMetricsStates();
      }
    },
    resetFetchMetricsStates() {
      this.metricsAbortController = null;
      this.loading = false;
    },
    changeFilters(filters) {
      this.selectedCommunities = filters.selectedCommunities;
      this.accessLevel = filters.accessLevel;
      this.fetchMetrics();
    },
    changeAccessLevel(accessLevel) {
      this.accessLevel = accessLevel;
      this.fetchMetrics();
    },
    async fetchMetricById(id, index, type) {
      let params = {
        startDate: this.rangeDate.selected[0],
        endDate: this.rangeDate.selected[1],
        accessLevel: this.accessLevel,
        comunityId: this.community ? this.community.id : null,
      };
      this.storeActions(params);
      try {
        const response = await this.$reportDataProvider.getOne(
          "dh_dashboard_metrics",
          {
            id: id,
            startDate: this.rangeDate.selected[0],
            endDate: this.rangeDate.selected[1],
            headers: {
              "x-scope-access-level": this.accessLevel,
              "x-quext-community-id": this.community ? this.community.id : null,
              "x-quext-profile": JSON.stringify(this.profile),
            },
          }
        );
        if (this.isEmpty(response)) {
          this.displayIsEmpty();
          return;
        }
        if (type === "singles") {
          this.metrics.singles.splice(index, 1, response);
        } else {
          this.metrics.charts.splice(index, 1, response);
        }
      } catch (error) {
        this.notifyError(error);
      }
    },
    storeActions(...params) {
      this.addActions(params);
    },
    remeberingSelectedActions() {
      this.rangeDate.selected[0] = this.preferences
        ? this.preferences[0].startDate
        : this.rangeDate.selected[0];
      this.rangeDate.selected[1] = this.preferences
        ? this.preferences[0].endDate
        : this.rangeDate.selected[1];
      this.accessLevel = this.preferences
        ? this.preferences[0].accessLevel
        : this.accessLevel;
    },
    validDate(startDate, endDate) {
      return !moment(startDate).isBefore(endDate);
    },
    setMinDate(date) {
      this.rangeDate.minDate = date;
    },
    handleChangeDatePicker(date) {
      this.rangeDate.selected = date;
    },
    async exportData(exportConfig) {
      this.isExporting = true;
      if (exportConfig.format === "csv") {
        exportToCsv(this.metrics.charts);
        await this.notifySuccess("CSV exported");
        this.isExporting = false;
      }
      if (exportConfig.format === "csv-clipboard") {
        copyCsvToClipboard(this.metrics.charts);
        await this.notifySuccess("Copied to clipboard");
        this.isExporting = false;
      }
      if (exportConfig.format === 'pdf') {
        this.$refs.dhData.classList.add('max-w-2xl'); // Add max width for the page content
        // We will use a timeout to ensure that the styles are applied correctly
        // We can't use nextTick here because the charts has a different refresh time after the class change
        setTimeout(async () => {
          await exportToPdf(this.$refs.dhData, 'page-matrics', 'page-break');
          this.$refs.dhData.classList.remove('max-w-2xl'); // Remove max width
          this.notifySuccess("PDF exported");
          this.isExporting = false;
        }, 400);
      }
    },
  },
  watch: {
    community: {
      handler: function () {
        this.selectedCommunities = [this.community];
        this.fetchMetrics();
      },
    },
    rangeDate: {
      handler(newDate, oldDate) {
        if (newDate[0] === oldDate[0] || newDate[1] === oldDate[1]) {
          this.fetchMetrics();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.fetchMetrics();
  },
  created() {
    this.remeberingSelectedActions();
  },
};
</script>
<style scoped>
.title {
  @apply leading-8 tracking-widest mt-0 mb-8 text-black;
  font-size: 2rem;
}

.custom-size {
  font-size: 0.8125rem;
}

.dropwidth {
  @apply w-40 py-0 text-xs;
}

.fullwidth {
  flex: 0 0 100%;
}

.middlewidth {
  flex: 0 0 50%;
}

.skeleton {
  @apply bg-gray-200 from-gray-200 to-gray-300 animate-pulse;
  height: 30vw;
}

.skeleton-card {
  @apply h-16 bg-gray-200 from-gray-200 to-gray-300 animate-pulse;
}

.collapsable {
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  max-height: 0;
}

.open {
  max-height: 400px;
}

.closed {
  max-height: 0;
  overflow: hidden;
}

.sticky-item {
  @apply bg-gray-100 sticky pt-2 pb-5;
  z-index: 1;
  top: 100px;
}

.card {
  height: calc(100% - 2rem);
}
</style>