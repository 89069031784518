<template>
  <div
    class="options-menu"
    :class="{ 'options-menu--exporting': disableState }"
    >
    <button
      class="options-menu__button"
      @click="toggleOptionsMenu"
      :disabled="loading"
      :class="[
        { 'options-menu__button--disabled': loading },
        `options-menu__button--${colorScheme}`
      ]"
    >
      <Icon name="DotsVertical" class="w-4 h-4" />
      <template v-if="showBtnTitle">options</template>
    </button>

    <div v-if="showOptions" ref="optionsMenuContent" class="options-menu__float-box">
      <div class="options-menu__content">
        <h2 class="text-base font-bold">Options</h2>
        <div v-if="showViewMode">
          <label for="export" class="option-field__label">
            view mode
            <Icon name="info" class="w-3 h-3" />
          </label>
          <dropdown
            id="accessLevel"
            v-model="selectedViewMode"
            :options="viewModeOptions"
            :placeholder="'select view level'"
            :searchable="false"
            :disabled="disableState"
            @input="updateViewMode"
            noClear
          ></dropdown>
        </div>
        <div class="flex items-end gap-2">
          <div class="w-full">
            <label for="export" class="option-field__label">
              export
            </label>
            <dropdown
              id="export"
              v-model="selectedExport"
              :options="exportSelectOptions"
              :placeholder="'select export'"
              :searchable="false"
              :disabled="disableState"
              noClear
            ></dropdown>
          </div>
          <button class="btn-primary btn-solid btn-export" :disabled="disableState" @click="handleExport">{{ exportBtnLabel }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthMixin from "@/components/auth/AuthMixin";
import Icon from "@/components/ui/Icon";
import Dropdown from "@/components/ui/Dropdown.vue";

export default {
  name: "OptionsMenu",

  mixins: [AuthMixin],

  emits: ['exportData', 'accessLevelChange'],

  components: {
    Dropdown,
    Icon,
  },

  props: {
    showViewMode: {
      type: Boolean,
      default: false
    },

    showBtnTitle: {
      type: Boolean,
      default: true,
    },

    preSelectViewMode: {
      type: Number,
      default: 3,
    },

    preSelectExport: {
      type: String,
      default: 'pdf',
    },

    // This will allow us to controls what export options will be available
    exportAvailableOptions: {
      type: Array,
      default: () => ['pdf', 'csv', 'csv-clipboard']
    },

    colorScheme: {
      type: String,
      default: 'purple',
      validator: (value) => ['purple', 'graphite'].includes(value)
    },

    loading: {
      type: Boolean,
      required: true,
    },

    isExporting: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showOptions: false,
      selectedViewMode: null,
      viewModeOptions: [
        {
          key: 3,
          value: "Advanced",
        },
        {
          key: 2,
          value: "Intermediate",
        },
        {
          key: 1,
          value: "Simple",
        },
      ],
      selectedExport: null,
      // Here we can define all the export options that can be used
      exportOptions: [
        {
          key: 'pdf',
          value: 'PDF',
          btnLabel: 'export'
        },
        {
          key: 'csv',
          value: 'CSV',
          btnLabel: 'export'
        },
        {
          key: 'csv-clipboard',
          value: 'CSV to Clipboard',
          btnLabel: 'copy'
        },
      ],
      exportingState: false,
    };
  },

  computed: {
    exportBtnLabel() {
      if (this.isExporting) {
        return 'Exporting'
      }

      return this.exportOptions.find(option => option.key === this.selectedExport).btnLabel || 'export';
    },

    exportSelectOptions() {
      return this.exportOptions.reduce((acc, option) => {
        if (this.exportAvailableOptions.includes(option.key)) {
          acc.push({
            key: option.key,
            value: option.value
          })
        }

        return acc;
      }, []);
    },

    disableState() {
      return this.exportingState || this.loading;
    },
  },

  watch: {
    preSelectViewMode(newValue) {
      this.selectedViewMode = newValue;
    },

    isExporting(newState) {
      this.exportingState = newState;

      if (!newState) {
        this.toggleOptionsMenu();
      }
    },
  },

  methods: {
    toggleOptionsMenu() {
      this.showOptions = !this.showOptions;
    },

    handleOutsideClickEvent(e) {
      if (!this.showOptions || this.exportingState) {
        return;
      }

      const el = this.$refs.optionsMenuContent;

      if (el && !el.contains(e.target) && !e.target.closest('.options-menu__button')) {
        this.toggleOptionsMenu();
      }
    },

    handleExport() {
      const exportData = {
        format: this.selectedExport,
      }

      if (this.showViewMode) {
        exportData.viewMode = this.selectedViewMode
      }

      this.exportingState = true;

      this.$emit('exportData', exportData);
    },

    updateViewMode() {
      this.$emit('accessLevelChange', this.selectedViewMode);
    }
  },
  
  mounted() {
    window.addEventListener('click', this.handleOutsideClickEvent);

    this.selectedViewMode = this.preSelectViewMode;
    this.selectedExport = this.preSelectExport;
  },

  beforeUnmount() {
    window.removeEventListener('click', this.handleOutsideClickEvent);
  },
};
</script>
  
<style scoped>
  .options-menu {
    @apply relative;
  }

  .options-menu__button,
  .options-menu__button.options-menu__button--purple {
    @apply flex items-center text-purple-350 gap-1;
    font-size: 0.8125rem;
  }
  
  .options-menu__button:hover,
  .options-menu__button.options-menu__button--purple:hover {
    @apply text-purple-400;
  }

  /* Button color scheme */
  .options-menu__button.options-menu__button--graphite {
    @apply text-graphite-800;
  }
  
  .options-menu__button.options-menu__button--graphite:hover {
    @apply text-graphite-900;
  }
  
  .options-menu__button--disabled,
  .options-menu__button--disabled:hover {
    @apply text-gray-700 cursor-wait !important;
  }
  
  .options-menu__float-box {
    @apply absolute right-0 top-0 bg-white content-center items-center  border border-gray-300 rounded-sm shadow-xs z-20;
    min-width: 360px;
  }
  
  .options-menu--exporting .options-menu__float-box {
    @apply cursor-wait;
  }

  .options-menu__content {
    @apply px-4 pt-3 pb-4 grid gap-4;
  }

  .options-menu .btn-export {
    @apply border-purple-350 bg-purple-350 text-white !important;
  }

  .options-menu .btn-export:hover {
    @apply opacity-75 !important;
  }

  .options-menu--exporting .btn-export {
    @apply opacity-75 cursor-wait !important;
  }

  .options-menu .option-field__label {
    @apply text-xs font-semibold tracking-wider flex items-center gap-1 mb-1;
  }
</style>
  