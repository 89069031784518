<template>
  <div class="flex flex-col">
    <header class="flex justify-between items-center gap-4">
      <h1 class="title" v-if="this?.status !== 'loading'">
        {{ title }}
      </h1>
      <button
        v-if="placeholderType === 'error' && !printView"
        @click="refresh"
        class="btn-primary flex gap-1 items-center btn-xs"
      >
        <Icon name="restore" class="w-2 h-2" />
        refresh
      </button>
    </header>
    <div class="flex mt-2 w-full">
      <DashboardPlaceholder
        v-if="placeholderType"
        variant="line"
        :type="placeholderType"
      />
      <h3 class="text-2xl pr-3 number" v-else>
        {{ total }}
      </h3>
    </div>
  </div>
</template>

<script>
  import DashboardPlaceholder from "@/components/report/dashboard/DashboardPlaceholder.vue";
  import Icon from "@/components/ui/Icon";

  export default {
    name: "CardTotal",
    components: {
      DashboardPlaceholder,
      Icon,
    },
    data() {
      return {
        refreshTriedCount: 0,
      };
    },
    props: {
      title: {
        type: String,
        required: true,
      },
      total: {
        type: Number,
        required: true,
      },
      status: {
        type: String,
        default: "success",
      },
      printView: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      placeholderType() {
        if (this?.status === "error") {
          return "error";
        }
        if (this?.status === "loading") {
          this.waitAndRefresh();
          return "loading";
        }
        if (/nan/gi.test(this.total) || this.total === null) {
          return "noData";
        }
        return false;
      },
    },
    methods: {
      refresh() {
        this.$emit("refresh");
      },
      waitAndRefresh() {
        if (this.refreshTriedCount < 3) {
          setTimeout(() => {
            this.refresh();
            this.refreshTriedCount++;
          }, 6000);
        }
      },
    },
  };
</script>

<style scoped>
  .title {
    @apply font-bold;
    font-size: 0.8125rem;
  }

  .number {
    color: #5d3a9a;
  }
</style>
