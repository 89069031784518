<template>
  <aside class="w-fit report-sidebar">
    <div class="report-sidebar__content">
      <h1 class="report-sidebar__title">{{ sidebarOptions.title }}</h1>

      <template v-if="sidebarOptions.blocks && sidebarOptions.blocks.length">
        <section v-for="sidebarBlock in sidebarOptions.blocks" :key="sidebarBlock.id" class="px-10 mb-10">
          <h3 class="tracking-wider text-black mb-4 text-base font-semibold">{{ sidebarBlock.title }}</h3>

          <nav v-if="sidebarBlock.blocks && sidebarBlock.blocks.length" class="report-sidebar__nav-block">
            <template v-for="optionBlock in sidebarBlock.blocks" :key="optionBlock.id">

              <h3 class="flex items-center w-full justify-between py-2 cursor-pointer mb-2"
                :class="{ 'text-gray-700 cursor-wait': optionBlock.options === null }"
                @click="optionBlock.options !== null ? toggleCollapse(optionBlock.id) : null">
                <p class="flex gap-2">
                  <Icon :name="optionBlock.icon" class="w-4 h-4" />
                  {{ optionBlock.title }}
                </p>
                <Icon name="chevronDown" class="w-4 h-4 transition-transform duration-300"
                  :class="{ 'transform rotate-180': collapsed.includes(optionBlock.id) || optionBlock.options === null }" />
              </h3>

              <ul v-if="optionBlock.options && optionBlock.options.length" class="report-sidebar__option-list"
                :class="!collapsed.includes(optionBlock.id) ? 'report-sidebar__option-list--open' : 'report-sidebar__option-list--closed'">
                <li v-for="option in optionBlock.options" :key="option.key" @click="selectOption(option)"
                  class="py-2 pr-3 pl-10 cursor-pointer" :class="{ 'bg-gray-200': activeOption === option.key }">
                  {{ option.title }}
                </li>
              </ul>
            </template>
          </nav>
        </section>
      </template>
    </div>
  </aside>
</template>

<script>
import Icon from "@/components/ui/Icon";

export default {
  name: "ReportSidebar",

  emits: ['selectedOption'],

  props: {
    /**
     * Sidebar options:
     * 
     * {
     *  title: String,
     *  blocks: [
     *    title: String,
     *    id: string,
     *    blocks: [
     *      {
     *        title: String,
     *        id: string,
     *        icon: String,
     *        options: [
     *          title: String,
     *          queryKey: String, // Optional, if you pass a value this option will be interpreted as query param
     *          key: String,
     *        ],
     *      },
     *    ],
     *  ],
     * };
     */
    sidebarOptions: {
      type: Object,
      required: true
    },

    defaultActive: {
      type: String,
      default: null,
    },
  },

  components: {
    Icon,
  },

  data() {
    return {
      collapsed: [],
      activeOption: null,
    }
  },

  methods: {
    toggleCollapse(key) {
      if (this.collapsed.includes(key)) {
        this.collapsed = this.collapsed.filter(item => item !== key);

        return;
      }

      this.collapsed.push(key);
    },

    selectOption(data) {
      let routerData = { path: data.key };
      let selectedType = 'path';

      if (!!data.queryKey) {
        routerData = {
          query: {
            [data.queryKey]: data.key,
          },
        };

        selectedType = data.queryKey
      }

      this.$emit('selectedOption', {
        type: selectedType,
        value: data.key,
      });

      this.activeOption = data.key;

      this.$router.push(routerData);
    },
  },

  mounted() {
    this.activeOption = this.defaultActive;
  },
};
</script>

<style scoped>
.report-sidebar__option-list {
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  max-height: 0;
}

.report-sidebar__option-list--open {
  max-height: 400px;
}

.report-sidebar__option-list--closed {
  max-height: 0;
  overflow: hidden;
}

.report-sidebar__nav-block {
  @apply text-gray-700 text-xs;

  font-size: 0.8125rem;
}

.report-sidebar__title {
  @apply leading-8 tracking-widest mt-0 mb-8 text-black;

  font-size: 2rem;
}

.report-sidebar__content {
  @apply bg-gray-100 sticky pt-2 pb-5;

  z-index: 1;
  top: 100px;
}
</style>