<template>
  <div class="relative">
    <button
      class="filters-btn"
      v-if="dropdown === ''"
      @click="openFilters"
      :disabled="loading"
      :class="{ 'filter-btn--disabled': loading }"
    >
      <Icon name="filter" class="w-4 h-4" />
      filters
      <div
        class="p-1 min-w-4 min-h-4 ml-1 rounded-full bg-purple-350 text-white text-xs grid place-items-center font-inter leading-none"
        v-if="selectedCommunities?.length > 0"
      >
        {{ selectedCommunities?.length }}
      </div>
    </button>
    <div v-if="dropdown === 'allFilters'" class="filters-wrapper">
      <div class="px-4 pt-3 pb-4 grid gap-4">
        <h2 class="text-base font-bold mb-1">Filters</h2>
        <div>
          <label
            for="accessLevel"
            class="text-xs font-semibold tracking-wider flex items-center gap-1 mb-1"
          >
            view mode
          </label>
          <dropdown
            id="accessLevel"
            v-model="selectedLevel"
            :options="viewModeOptions"
            :placeholder="'select view level'"
            :searchable="false"
            noClear
          ></dropdown>
        </div>
        <div>
          <label
            for="accessLevel"
            class="text-xs font-semibold tracking-wider flex items-center gap-5 mb-2 justify-between"
          >
            <p>communities</p>

            <button class="flex gap-1 items-center text-purple-350 font-semibold hover:text-purple-400 hover:underline
              disabled:opacity-50 disabled:cursor-not-allowed
            "
              @click="newSelectedCommunities = []"
              :disabled="!newSelectedCommunities.length"
            >
              clear all communities
            </button>
          </label>
          <div class="flex gap-2 flex-wrap">
            <button
              v-if="community"
              class="btn-community"
              @click="removeCommunity(0)"
            >
              {{ community.name }}
              <Icon name="close" class="w-2 h-2" />
            </button>

            <button
              class="btn-community"
              @click="removeCommunity(index)"
              v-for="(community, index) in newSelectedCommunities.slice(1, 5)"
              :key="community.id"
            >
              <span>{{ community.name }}</span>
              <Icon name="close" class="w-2 h-2" />
            </button>
            <button
              v-if="newSelectedCommunities.length > 5"
              class="btn-community"
              @click="dropdown = 'communitiesFilters'"
            >
              + {{ newSelectedCommunities.length - 5 }}
            </button>
            <button
              class="btn-community"
              @click="dropdown = 'communitiesFilters'"
            >
              add
              <Icon name="plusCircle" class="w-3 h-3" />
            </button>
          </div>
        </div>
      </div>
      <div class="flex justify-end gap-3 border-t py-3 px-4 border-gray-300">
        <button class="btn-community" @click="dropdown = ''">cancel</button>
        <button class="btn-community" @click="changeFilters">apply</button>
      </div>
    </div>
    <div
      v-if="dropdown === 'communitiesFilters'"
      class="filters-wrapper filters-wrapper--communities"
    >
      <div class="grid grid-cols-2">
        <div class="px-4 pt-3 border-r border-gray-300">
          <h2 class="text-base font-bold mb-2">Communities</h2>
          <TextInput v-model="search" placeholder="search">
            <template v-if="trailingIcon" v-slot:trailing-icon>
              <Icon class="w-4 h-4" name="search2" />
            </template>
          </TextInput>
          <ul class="communities-list mt-5">
            <li v-for="community in communitiesOptions" :key="community.id">
              <button
                class="flex items-center gap-2"
                @click="selectCommunity(community)"
              >
                <span>{{ community.name }}</span>
              </button>
            </li>
          </ul>
        </div>
        <div class="px-4 pt-3">
          <h2 class="text-base font-bold mb-1">Selected communities</h2>
          <ul class="communities-list communities-list--selected">
            <li>
              <button
                disabled
                class="btn-community btn-community--disabled cursor-not-allowed"
                v-if="community"
              >
                <span>{{ community.name }}</span>
              </button>
            </li>
            <li
              v-for="(community, index) in newSelectedCommunities"
              :key="community.id"
            >
              <button class="btn-community" @click="removeCommunity(index)">
                <span>{{ community.name }}</span>
                <Icon name="close" class="w-2 h-2" />
              </button>
            </li>
          </ul>
          <button
            class="btn-community border-none mt-2"
            @click="newSelectedCommunities = []"
          >
            <span>clear all</span>
          </button>
        </div>
      </div>
      <div
        class="flex justify-between gap-3 border-t py-3 px-4 border-gray-300"
      >
        <button class="btn-community" @click="selectAllCommunities">
          select all
        </button>
        <div class="flex gap-3">
          <button class="btn-community" @click="dropdown = ''">cancel</button>
          <button class="btn-community" @click="changeFilters">apply</button>
        </div>
      </div>
    </div>
    <div
      v-if="dropdown !== ''"
      class="fixed inset-0 opacity-50 z-10"
      @click="dropdown = ''"
    />
  </div>
</template>

<script>
import Dropdown from "@/components/ui/Dropdown.vue";
import AuthMixin from "@/components/auth/AuthMixin";
import { mapGetters } from "vuex";
import Icon from "@/components/ui/Icon";
import TextInput from "@/components/ui/TextInput";

export default {
  name: "Filters",
  mixins: [AuthMixin],
  components: {
    Dropdown,
    Icon,
    TextInput,
  },
  props: {
    accessLevel: {
      type: String,
      required: true,
    },
    selectedCommunities: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      viewModeOptions: [
        {
          key: 3,
          value: "Advanced",
        },
        {
          key: 2,
          value: "Intermediate",
        },
        {
          key: 1,
          value: "Simple",
        },
      ],
      newSelectedCommunities: [],
      selectedLevel: null,
      search: "",
      dropdown: "",
    };
  },
  computed: {
    ...mapGetters({
      preferences: "report_dashboard/getDashboardPreferences",
    }),
    communitiesOptions() {
      return this.communities?.filter((community) => {
        if (this.newSelectedCommunities.some((c) => c.id === community.id)) {
          return false;
        }
        if (this.search) {
          return community.name
            .toLowerCase()
            .includes(this.search.toLowerCase());
        }
        return true;
      });
    },
  },
  methods: {
    selectCommunity(community) {
      this.newSelectedCommunities = [...this.newSelectedCommunities, community];
    },
    selectAllCommunities() {
      this.newSelectedCommunities = this.communities;
    },
    removeCommunity(index) {
      this.newSelectedCommunities.splice(index, 1);
    },
    changeFilters() {
      this.dropdown = "";
      this.$emit("changeFilters", {
        selectedCommunities: this.newSelectedCommunities,
        accessLevel: this.selectedLevel,
      });
    },
    openFilters() {
      this.dropdown = "allFilters";
      this.newSelectedCommunities = this.selectedCommunities;
      this.selectedLevel = this.accessLevel;
    },
  },
};
</script>

<style scoped>
.filters-btn {
  @apply flex items-center text-purple-350 gap-1;
  font-size: 0.8125rem;
}

.filters-btn:hover {
  @apply text-purple-400;
}

.filter-btn--disabled {
  @apply text-gray-700 cursor-wait;
}

.filter-btn--disabled:hover {
  @apply text-gray-700;
}

.filters-wrapper {
  @apply absolute right-0 top-0 bg-white content-center items-center  border border-gray-300 rounded-sm shadow-xs z-20;
  min-width: 360px;
}

.filters-wrapper--communities {
  min-width: 560px;
}

.communities-list {
  @apply overflow-y-scroll;
  height: 228px;
}

.communities-list--selected {
  @apply flex flex-wrap gap-2 mt-4;
  max-height: 228px;
  height: auto;
}

.communities-list::-webkit-scrollbar {
  display: none;
}
.communities-list {
  scrollbar-width: none;
}

.btn-community {
  @apply text-purple-350 border border-purple-350 font-semibold tracking-wider flex items-center gap-1 px-2 py-1;
  font-size: 0.6875rem;
}
.btn-community:hover {
  @apply bg-active-150;
}
.btn-community--disabled {
  @apply border-gray-700 text-gray-700 cursor-not-allowed;
}
.btn-community--disabled:hover {
  @apply bg-transparent;
}
</style>
