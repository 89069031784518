<template>
  <div class="flex gap-5 items-center card">
    <div class="w-full">
      <canvas :id="uniqueId"></canvas>
    </div>
  </div>
</template>
<script>
  import Chart from "chart.js/auto";
  import ChartDataLabels from "chartjs-plugin-datalabels";
  import { getChartData } from "./config";
  import { colors } from "./colors";

  export default {
    name: "CustomChart",
    props: {
      type: {
        // "bar" | "bar-horizontal" | "line" | "doughnut"
        type: String,
        required: true,
      },
      dataChart: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        labelsColor: colors,
        uniqueId: `chart-${Math.random().toString(36).substr(2, 9)}`,
      };
    },
    methods: {
      createChart(chartId, charData) {
        const ctx = document.getElementById(chartId);
        new Chart(ctx, {
          plugins: [ChartDataLabels],
          type: charData.type,
          data: charData.data,
          options: charData.options,
        });
      },
      renderChart() {
        const chartData = getChartData(this.type, this.dataChart);
        this.createChart(this.uniqueId, chartData);
      },
    },
    mounted() {
      this.renderChart();
    },
  };
</script>

<style scoped>
  .card {
    height: calc(100% - 2rem);
  }

  .custom-font-size {
    font-size: 0.8125rem;
  }
</style>
