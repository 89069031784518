<template>
  <div class="flex gap-5 items-center justify-center card">
    <div class="w-2/4">
      <canvas :id="uniqueId"></canvas>
    </div>
    <div class="flex-1 w-full max-w-xs">
      <ul class="flex flex-col gap-2 justify-center h-full">
        <li
          class="flex gap-2 justify-between font-frank text-sm"
          v-for="(item, index) in dataChart.labels"
          :key="item.id"
        >
          <div class="flex gap-2 items-center">
            <div
              class="w-3 h-3 rounded-sm"
              :style="{ backgroundColor: labelsColor[index] }"
            />
            <span class="custom-font-size">{{ item }}</span>
          </div>
          {{ ((dataChart.data[0].values[index] / total) * 100).toFixed(0) }}%
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
  import Chart from "chart.js/auto";
  import ChartDataLabels from "chartjs-plugin-datalabels";
  import { getDoughnutChartData } from "./config";
  import { colors } from "./colors";

  export default {
    name: "CustomChartDoughnut",
    props: {
      dataChart: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        labelsColor: colors,
        uniqueId: `chart-${Math.random().toString(36).substr(2, 9)}=${
          this.dataChart.dhId
        }`,
        total: this.dataChart?.data?.[0]?.values?.reduce(
          (a, b) => a + Number(b),
          0
        ),
      };
    },
    methods: {
      createChart(chartId, charData) {
        const ctx = document.getElementById(chartId);
        new Chart(ctx, {
          plugins: [ChartDataLabels],
          type: charData.type,
          data: charData.data,
          options: charData.options,
        });
      },
      renderChart() {
        const chartData = getDoughnutChartData(this.dataChart, this.total);
        this.createChart(this.uniqueId, chartData);
      },
    },
    mounted() {
      this.renderChart();
    },
  };
</script>

<style scoped>
  .card {
    height: calc(100% - 2rem);
  }

  .custom-font-size {
    font-size: 0.8125rem;
  }
</style>
