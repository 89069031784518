import store from '@/store';
import { colors } from "./colors";
import { subtitles } from "./subtitles";

export const getDataSets = (chartData, type) => {
  return chartData.map(({ legend, values, type: customType }) => {
    const currentColor = getItemColor(type, legend);

    return {
      type: customType || type,
      label: legend,
      data: values,
      borderColor: currentColor,
      backgroundColor: currentColor,
      maxBarThickness: 17,
      borderRadius: {
        topRight: 3,
        bottomRight: 3,
      },
    };
  });
};

const getChartData = (charType, chartData) => {
  const type = charType.split("-")[0];
  const direction = charType.split("-")[1] ? "y" : "x";
  const isLineWithSingleXResult = type === "line" && chartData?.labels?.length <= 1;
  let chartSubtitle = null;

  if (Object.keys(subtitles).find(key => key === chartData.dhId)) {
    chartSubtitle = {
      display: true,
      position: 'left',
      color: '#8E8E8E',
      font: {
        size: 12,
        weight: '400'
      },
      text: subtitles[chartData.dhId],
    }
  }

  return {
    type: type || "bar",
    data: {
      id: chartData.dhId,
      labels: chartData.labels,
      datasets: getDataSets(chartData.data, type),
    },
    options: {
      hover: {
        intersect: false
      },
      layout: {
        padding: {
          left: 5,
          right: 20,
          top: 20,
          bottom: 5,
        },
      },
      aspectRatio: 5 / 3,
      indexAxis: direction,
      plugins: {
        tooltip: {
          mode: 'index',
          itemSort: function (a, b) {
            return b.parsed.y - a.parsed.y;
          },
          callbacks: {
            width: 5,
            label: function (context) {
              const value = context.dataset.data[context.dataIndex];

              if (type === 'line' && chartData.data.length > 1) {
                return ` ${context.dataset.label} ${Number(value).toLocaleString('en')}`;
              }

              if (/rate/gi.test(chartData?.title)) {
                return ` ${value}%`;
              }

              const total = context.dataset.data.reduce((a, b) => a + Number(b), 0);
              const percent = ((value / total) * 100).toFixed(0);

              return ` ${Number(value).toLocaleString("en")} (${percent}%)`;
            },
          },
        },
        legend: {
          display:
            type === "line" && chartData?.data?.some((item) => item.legend),
          position: "bottom",
        },
        datalabels: {
          display: type === "bar",
          align: "start",
          anchor: "end",
          color: "#ffffff",
          formatter: (value) => {
            const maxVal = Math.max(...chartData.data[0].values);
            if (value < maxVal / 10) {
              return "";
            }

            if (/rate/gi.test(chartData?.title)) {
              return `${value}%`;
            }
            return Number(value).toLocaleString('en');
          },
          font: {
            size: 12,
          },
        },
        subtitle: chartSubtitle,
      },
      elements: {
        point: {
           radius: (chartData?.data?.length > 1 && !isLineWithSingleXResult) ? 0 : 3,
          hoverRadius: 3,
        },
      },
      scales: {
        x: {
          offset: isLineWithSingleXResult,
          grid: {
            display: type === "line" || (type === "bar" && direction === "y"),
            color: "#F4F4F4",
            borderColor: "transparent",
          },
          beginAtZero: true,
          ticks: {
            callback: function (value) {
              if (
                type === "bar" &&
                direction === "y" &&
                /rate/gi.test(chartData?.title)
              ) {
                return (value > 0 ? value.toFixed(1) : value) + "%";
              }
              return this.getLabelForValue(value);
            },
          },
        },
        y: {
          grid: {
            display: type === "line",
            color: "#F4F4F4",
            borderColor: "transparent",
          },
          beginAtZero: true,
          ticks: {
            callback: function (value) {
              if (type === "line" && /rate/gi.test(chartData?.title)) {
                return (value > 0 ? value.toFixed(1) : value) + "%";
              }
              return this.getLabelForValue(value);
            },
          },
        },
      },
    },
  };
};

export const getDataSetsDoughnut = (chartData) => {
  return chartData.map(({ legend, values, type: customType }) => {
    return {
      type: customType,
      label: legend,
      data: values,
      borderColor: 'white',
      backgroundColor: colors,
      borderWidth: 1,
      maxBarThickness: 17,
    };
  });
};

const getDoughnutChartData = (chartData, total) => {
  return {
    type: "doughnut",
    data: {
      id: chartData.dhId,
      labels: chartData.labels,
      datasets: getDataSetsDoughnut(chartData.data),
    },
    options: {
      layout: {
        padding: {
          left: 0,
          right: 20,
          top: 20,
          bottom: 0,
        },
      },
      aspectRatio: 1,
      plugins: {
        tooltip: {
          callbacks: {
            width: 5,
            label: function (context) {
              const value = context.dataset.data[context.dataIndex];
              const label = context.label;
              return [
                label,
                `${((value / total) * 100).toFixed(0)}% (${Number(value).toLocaleString('en')})`];
            },
          },
        },
        legend: {
          display: false,
        },
        datalabels: {
          display: true,
          align: "center",
          anchor: "center",
          color: "#ffffff",
          formatter: (value) => {
            const percent = ((value / total) * 100).toFixed(0)
            if (percent < 10) return "";
            return `${percent}%`;
          },
          font: {
            size: 12,
          },
        },
      },
      scales: {
        x: {
          display: false,
        },
        y: {
          display: false
        },
      },
    },
  };
};

/**
 * Get item color based on key and graph type
 * @param {string} graphType the type of the graph
 * @param {string} key the key used to identify the graph info
 * @returns color hex
 */
function getItemColor(graphType, key) {
  // If graph is line or hasn't key we can return the same color
  if (graphType !== 'line' || !key) {
    return colors[0]
  }

  // Search for the color on the store using the received key
  const foundColor = store.getters['report_dashboard/getDashboardGraphColor'](key);

  if (foundColor) {
    return foundColor;
  }

  // Define a new color based on the current number of used colors on the store
  const usedColors = store.getters['report_dashboard/getDashboardGraphColors'];
  const newColor = colors[usedColors ? Object.keys(usedColors).length : 0];

  // TEMPORARY SOLUTION UNTIL COLOR PALETTE DEFINITIONS: Fallback to the first color if we reach the maximum allowed colors from the list
  if (!newColor) {
    return colors[0]
  }

  // Save the new color with the key association
  store.dispatch('report_dashboard/setGraphColor', {
    ...usedColors, // Current color list
    [key]: newColor, // New color
  });

  return newColor;
}

export { getChartData, getDoughnutChartData };
