<template>
  <div
    class="board"
    :class="{
      'board--line': variant === 'line',
      'board--error': type === 'error',
      'board--loading': type === 'loading',
    }"
  >
    <p v-if="type === 'error'">Unable to load the data</p>
    <p v-else-if="type === 'loading'">loading...</p>
    <p v-else>No data for these parameters</p>
    <div class="loading-dots" v-if="type === 'loading'">
      <div class="loading-dots--dot"></div>
      <div class="loading-dots--dot"></div>
      <div class="loading-dots--dot"></div>
    </div>
    <Icon v-else name="warning2" class="h-4 w-4" />
  </div>
</template>

<script>
import Icon from "@/components/ui/Icon";

export default {
  name: "DashboardPlaceholder",
  props: {
    variant: {
      type: String,
      default: "board",
    },
    type: {
      type: String,
      default: "noData",
    },
  },
  components: {
    Icon,
  },
};
</script>

<style scoped>
.board {
  @apply w-full bg-blue-50 border-purple-300 text-purple-300 flex items-center justify-center flex-col gap-4 text-xs font-frank;
  min-height: 172px;
  height: calc(100% - 2rem);
}

.board--line {
  @apply flex-row-reverse gap-3 text-xs p-3 h-auto min-h-0 justify-end;
}

.board--error {
  @apply bg-error border-error text-error;
}

.board--loading {
  @apply text-purple-400 gap-1;
}

.board--loading.board--line {
  @apply bg-graphite-300 flex-col;
}

@keyframes dot-keyframes {
  0% {
    opacity: 0.5;
    transform: scale(1, 1);
  }

  50% {
    opacity: 1;
    transform: scale(1.2, 1.2);
  }

  100% {
    opacity: 0.5;
    transform: scale(1, 1);
  }
}

.loading-dots {
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin: 0 auto;

  &--dot {
    animation: dot-keyframes 1.5s infinite ease-in-out;
    background-color: #2573a7;
    border-radius: 11px;
    display: inline-block;
    height: 11px;
    width: 11px;

    &:nth-child(2) {
      animation-delay: 0.5s;
    }

    &:nth-child(3) {
      animation-delay: 1s;
    }
  }
}
</style>
