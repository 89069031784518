<template>
  <!-- Expanded card -->
  <div class="fixed top-0 left-0 right-0 bottom-0 z-20 grid place-items-center" v-if="showExpanded">
    <div class="absolute top-0 left-0 right-0 bottom-0 bg-black opacity-75" @click="switchExpanded" />

    <div class="z-10 bg-white shadow-lg w-11/12 max-w-6xl h-5/6 max-h-3xl overflow-hidden p-5">
      <header class="flex mb-2 justify-between gap-4 items-center">
        <h4 class="font-bold text-xs leading-5">{{ chart.title }}</h4>
        <button @click="switchExpanded" v-if="!placeholderType || placeholderType === 'success'">
          <icon name="close" class="w-4 h-4 text-graphite-800 hover:text-graphite-900" />
        </button>
      </header>
      <component fullWidth v-bind:is="
        chart.type === 'doughnut' ? 'CustomChartDoughnut' : 'CustomChart'
      " :type="chart.type" :dataChart="chart"></component>
    </div>
  </div>
  <!-- Card content -->
  <div :ref="chart.dhId" class="card-metric">
    <header class="flex mb-2 justify-between gap-4 items-center">
      <h4 class="font-bold text-xs leading-5">{{ chart.title }}</h4>
      <div v-if="!printView" class="flex items-center gap-x-2">
        <button v-if="placeholderType === 'error'" class="btn-primary flex gap-1 items-center btn-xs" @click="refresh">
          <Icon name="restore" class="w-2 h-2" />
          refresh
        </button>
        <template v-else>
          <button v-show="!isExporting" @click="switchExpanded"
            v-if="!placeholderType || placeholderType === 'success'">
            <Icon name="resize" class="w-4 h-4 text-graphite-800 hover:text-graphite-900" />
          </button>
          <OptionsMenu v-show="!isExporting" :show-btn-title="false" :isExporting="isExporting" colorScheme="graphite"
            @exportData="exportData" />
        </template>
      </div>
    </header>
    <DashboardPlaceholder v-if="placeholderType" :type="placeholderType" />
    <component v-bind:is="
      chart.type === 'doughnut' ? 'CustomChartDoughnut' : 'CustomChart'
    " :type="chart.type" :dataChart="chart" v-else></component>
  </div>
</template>

<script>
import DashboardPlaceholder from "@/components/report/dashboard/DashboardPlaceholder.vue";
import CustomChart from "@/components/report/dashboard/CustomChart.vue";
import Icon from "@/components/ui/Icon";
import CustomChartDoughnut from "./CustomChartDoughnut";
import OptionsMenu from "@/components/report/dashboard/OptionsMenu.vue";
import { exportToCsv, copyCsvToClipboard, exportToPdf } from "@/components/report/common/exportContent";
import { mapActions } from "vuex";

export default {
  name: "CardMetric",
  components: {
    DashboardPlaceholder,
    CustomChart,
    Icon,
    CustomChartDoughnut,
    OptionsMenu,
  },
  data() {
    return {
      refreshTriedCount: 0,
      showExpanded: false,
      isExporting: false,
    };
  },
  props: {
    chart: {
      type: Object,
      required: true,
    },

    printView: {
      type: Boolean,
      defualt: false
    },
  },
  computed: {
    placeholderType() {
      if (this.chart?.status === "error") {
        return "error";
      }
      if (this.chart?.status === "loading") {
        this.waitAndRefresh();
        return "loading";
      }
      if (!this.chart?.data?.some((item) => item?.values?.length)) {
        return "noData";
      }
      return false;
    },
  },
  methods: {
    ...mapActions({
      notifySuccess: "alerts/notifySuccess",
    }),

    switchExpanded() {
      this.showExpanded = !this.showExpanded;
    },

    refresh() {
      this.$emit("refresh");
    },

    waitAndRefresh() {
      if (this.refreshTriedCount < 3) {
        setTimeout(() => {
          this.refresh();
          this.refreshTriedCount++;
        }, 6000);
      }
    },

    async exportData(dataConfig) {
      this.isExporting = true;

      if (dataConfig.format === "csv") {
        await exportToCsv([this.chart]);
        this.notifySuccess("CSV exported");
      }

      if (dataConfig.format === "csv-clipboard") {
        await copyCsvToClipboard([this.chart]);
        this.notifySuccess("Copied to clipboard");
      }

      if (dataConfig.format === 'pdf') {
        await exportToPdf(this.$refs[this.chart.dhId], this.chart.dhId);
        this.notifySuccess("PDF exported");
      }

      this.isExporting = false;
    },
  },
};
</script>

<style scoped>
.card-metric {
  @apply bg-white w-full;
}

.title {
  @apply font-bold;
  font-size: 0.8125rem;
}

.number {
  color: #5d3a9a;
}
</style>
