import html2pdf from 'html2pdf.js';

function exportToPdf(exportElement, docName = 'metrics', pageBreakClass = 'page-break') {
    var opt = {
        margin: 0.5,
        filename: `${docName}.pdf`,
        image: { type: 'jpeg', quality: 1 },
        jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
        pagebreak: { after: `.${pageBreakClass}` },
    };

    return html2pdf().set(opt).from(exportElement).save();
}

function generateCsv(charts) {
    let csv = '';

    charts.map(chart => {
        const labels = chart.labels?.join(',');
        csv += `${chart.title}\n,${labels}\n`;

        chart.data.map(data => {
            const values = data.values.join(',');
            csv += `${data.legend},${values}\n`;
        });

        csv += '\n';
    });

    return csv;
}

function exportToCsv(charts) {
    return new Promise(resolve => {
        let csv = generateCsv(charts);

        const blob = new Blob([csv], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('download', 'metrics.csv');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        resolve();
    });
}

function copyCsvToClipboard(charts) {
    return new Promise(resolve => {
        let csv = generateCsv(charts);

        navigator.clipboard.writeText(csv);

        resolve();
    });
}

export { exportToPdf, exportToCsv, copyCsvToClipboard };
