<template>
    <div class="flex flex-col justify-center opacity-0 fixed-footer" :class="positionClass">
        <div class="flex justify-center">
            <router-link :to="{name: 'home'}" class="toolbar-link">
                <icon name="search" class="h-5 w-5"></icon>
            </router-link>
            <router-link :to="{name: 'bookmarks'}" class="toolbar-link">
                <icon name="bookmark" class="h-5 w-5"></icon>
            </router-link>
            <router-link :to="{name: 'dashboard'}" class="toolbar-link">
                <icon name="chart" class="h-5 w-5" ></icon>
            </router-link>
        </div>
        <div v-if="commitHash" class="flex justify-center text-xs text-gray-400 mt-2">build #{{commitHash}}</div>
    </div>

</template>

<script>
    import Auth from "@/mixins/Auth";
    import Icon from "@/components/ui/Icon";
    import getEnvVariable from "@/utils/getEnvVariable";

    export default {
        mixins: [ Auth ],
        components: {Icon},
        data: () => {
            return {
                positionClass: null
            }
        },
        computed: {
            commitHash() {
                return getEnvVariable('VUE_APP_COMMIT_HASH')
            }
        },
        methods: {
            adjustToolbarPosition() {
                const topBar = document.getElementById('top-bar');
                const appView = document.getElementById('app-view');

                if (!topBar || !appView) {
                    return;
                }

                const topBarHeight = topBar.offsetHeight + 48;
                const appViewHeight = appView.offsetHeight;
                const contentHeight = topBarHeight + appViewHeight;
                const documentHeight = window.innerHeight;
                this.positionClass = (contentHeight > documentHeight) ? 'opacity-100 relative mt-8' : 'opacity-100 block absolute bottom-4 left-0 right-0';
            }
        },
        watch: {
            customerId() {
                // when a customer is selected from the top bar dropdown
                // the page (having the toolbar) content can change.
                this.adjustToolbarPosition();
            }
        },
        mounted() {
            this.$nextTick( () => {
                // wait until all dom is loaded
                this.adjustToolbarPosition();
            });

            window.addEventListener('resize', () => {
                this.adjustToolbarPosition();
            });
        }
    }
</script>

<style scoped>
    .toolbar-link {
        @apply bg-white rounded p-4 mx-2 rounded-full text-gray-600;
    }
    .router-link-exact-active ,
    .toolbar-link:hover{
        @apply text-blue-500;
    }
    .fixed-footer {
      position: fixed;
      width: 100%;
      text-align: center;
      bottom: 0;
      right: 0;
      background: #ffffff;
    }
</style>
