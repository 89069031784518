<template>
    <div class="grid h-full">
        <div class="loading">
            <span class="text-loading">Loading...</span>
            <div class="dot-flashing"></div>
        </div>
    </div>
</template>
<script>
export default {
    name: "Loading",
}
</script>
<style scoped>
.loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    height: 50vh; 
}

.text-loading {
    @apply font-frank font-500;
    font-size: 14px;
    margin: 10px 0;
}

.dot-flashing {
    @apply text-purple-500 bg-purple-500 relative;
    position: relative;
    width: 11px;
    height: 11px;
    border-radius: 11px;
    animation: dotFlashing 800ms 1800ms  ease-out infinite;
}

.dot-flashing::before,
.dot-flashing::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
}

.dot-flashing::before {
    @apply text-purple-500 bg-purple-500;
    left: -20px;
    width: 11px;
    height: 11px;
    border-radius: 11px;
    animation: dotFlashing 800ms ease-out infinite;
    animation-delay: 0s;
}


.dot-flashing::after {
    @apply text-purple-500 bg-purple-500;
    left: 20px;
    width: 11px;
    height: 11px;
    border-radius: 11px;
    animation: dotFlashing 800ms 2000ms ease-out infinite;
}

@keyframes dotFlashing {
    0% {
        box-shadow: 0px 0px 0px 1px rgba(103,63,178,1);
     }

    50%,
    100% {
        box-shadow: none;
        background-color: #D7C8F1;
    }
}
</style>