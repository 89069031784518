<template>
  <div class="font-frank flex font-normal">
    <ReportSidebar :sidebar-options="sidebarOptions" :default-active="selectedDashboard"
      @selectedOption="updateSidebar" />

    <main class="flex-1 pb-24">
      <DashboardIndex :dashboardId="selectedDashboard" />
    </main>
  </div>

  <HomepageBottomToolbar></HomepageBottomToolbar>
</template>
  
<script>
import AuthMixin from "@/components/auth/AuthMixin";
import NotifyMixin from "@/mixins/NotifyMixin";

import HomepageBottomToolbar from '@/components/layout/HomepageBottomToolbar';
import DashboardIndex from '@/views/report/dashboard/Index.vue';
import ReportSidebar from '@/components/report/common/ReportSidebar.vue';

export default {
  name: 'ReportIndex',

  mixins: [AuthMixin, NotifyMixin],

  components: {
    HomepageBottomToolbar,
    DashboardIndex,
    ReportSidebar,
  },

  data() {
    return {
      sidebarOptions: {
        title: 'Analytics',
        blocks: [
          {
            title: 'Dashboard',
            id: 'dashboard',
            blocks: [
              {
                title: 'Dashboard',
                icon: 'dashboard',
                id: 'dashboard-list',
                options: [
                  {
                    title: 'Digital human dashboard',
                    queryKey: 'dashboardId',
                    key: 'digital-human'
                  },
                ]
              }
            ]
          },
          {
            title: 'Reports',
            id: 'tabular-reports',
            blocks: [
              {
                title: 'Reports',
                icon: 'folder',
                id: 'reports-list',
                options: null
              }
            ]
          },
        ],
      },
      selectedDashboard: this.$route.query.dashboardId || 'digital-human',
      currentPath: this.$route.path || '/dashboard',
    };
  },

  methods: {
    updateSidebar(data) {
      if (data.type === 'path') {
        this.currentPath = data.value;

        return;
      }

      if (data.type === 'dashboardId') {
        this.selectedDashboard = data.value;

        return;
      }
    },

    async fetchTabularReportSidebar() {
      try {
        const response = await this.$reportDataProvider.getList(
          "report_sidebar",
          {
            headers: {
              "x-scope-access-rol": this.role,
              "x-quext-access-quext-admin": this.isQuextAdmin,
              "x-scope-access-profileId": this.profileId,
              "x-scope-access-cognitoId": this.cognitoId,
            },
          }
        );

        this.handleTabularReportSidebar(response);
      } catch (error) {
        this.notifyError(error);
      }
    },

    handleTabularReportSidebar(optionList) {
      const tabularReportBlock = this.sidebarOptions.blocks.find(sidebarBlocks => sidebarBlocks.id === 'tabular-reports')?.blocks.find(reportBlock => reportBlock.id === 'reports-list');
      let menuOptions = [];

      if (tabularReportBlock) {
        optionList.reduce((acc, item) => {
          acc.push({
            title: item.title,
            key: item.path
          });

          return acc;
        }, menuOptions);
      }

      tabularReportBlock.options = menuOptions;
    }
  },

  mounted() {
    this.fetchTabularReportSidebar();
  },
};
</script>

<style scoped>

</style>
  