<template>
  <Datepicker
    ref="datepicker"
    autocomplete="off"
    range
    multiCalendars
    multiCalendarsSolo
    :month-year-component="customMonthYearPicker"
    :multiStatic="false"
    :enableTimePicker="false"
    :modelValue="innerValue"
    @internalModelChange="changeValue"
    @cleared="onCleared"
    @closed="onClose"
    @open="onOpen"
    @updateMonthYear="(val) => $emit('updateMonthYear', val)"
    v-bind="filteredAttrs"
    :disabled="loading"
    class="dp-range"
    :class="{ 'dp-range--loading': loading }"
    menuClassName="dp-range-menu"
    >

    <template #dp-input>
      <slot name="input" v-bind="{ value }">
        <text-input :value="datePreview" :name="$attrs.name || 'calendarResult'"
          :inputClass="[{ 'form-input--focus': isOpen }, 'dp-range__input']" :placeholder="$attrs.placeholder || ''"
          autocomplete="off" readonly>
          <template #trailing-icon>
            <icon class="w-4 h-4 text-graphite-800" name="calendar2" />
          </template>
        </text-input>
      </slot>
    </template>

    <template #action-select>
      <button class="btn-primary btn-cancel" type="button" @click="cancel">cancel</button>
      <button class="btn-primary btn-solid btn-apply" type="button" @click="applyValue">apply</button>
    </template>

    <template #action-preview>
      <div class="manual-date">
        <text-input type="text" name="startDateText" :value="startDateText" readonly :class="['manual-date__input', { 'manual-date__input--no-date': !innerValue[0] }]"/>
        <text-input type="text" name="endDateText" :value="endDateText" readonly :class="['manual-date__input', { 'manual-date__input--no-date': !innerValue[1] }]"/>
      </div>
    </template>

    <template #clear-icon></template>
  </Datepicker>
</template>

<script>
import Icon from "@/components/ui/Icon";
import Datepicker from "@vuepic/vue-datepicker";
import TextInput from "@/components/ui/TextInput";
import CustomMonthYearPicker from "@/components/ui/datepicker/CustomMonthYearPicker";
import moment from "moment";

// We are removing range and multicalendar related attrs from being passed from parent to this component
const BLOCKED_ATTRS = ['range', 'multicalendars', 'multi-calendars', 'multicalendarssolo', 'multi-calendars-solo', 'month-year-component', 'multistatic', 'multi-static', 'enabletimepicker', 'enable-time-picker']


export default {
  inheritAttrs: false,

  components: { Datepicker, TextInput, Icon },

  props: {
    startDate: {
      type: Array,
      default: () => [moment().format(this.parentFormat), moment().format(this.parentFormat)],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    parentFormat: {
      type: String,
      default: 'MM/DD/YYYY',
    },
  },

  emits: [
    "apply",
    "change",
    "close",
    "updateMonthYear",
    "open",
  ],

  data() {
    return {
      isOpen: false,
      innerValue: [],
      startDateText: null,
      endDateText: null,
    };
  },

  computed: {
    customMonthYearPicker() {
      return CustomMonthYearPicker;
    },

    datePreview() {
      if (!this.innerValue.length) {
        return '';
      }

      const [startDate, endDate] = this.innerValue;

      return `${this.getFormattedDate(startDate, this.parentFormat)} - ${endDate ? this.getFormattedDate(endDate, this.parentFormat) : ''}`;
    },

    filteredAttrs() {
      // We are filtering the received attrs with de blocked attrs.
      return Object.keys(this.$attrs).reduce((acc, key) => {
        if (!BLOCKED_ATTRS.includes(key.toLowerCase())) {
          acc[key] = this.$attrs[key];
        }

        return acc;
      }, {});
    },
  },

  watch: {
    innerValue: function (newDates) {
      this.updateManualDateInput(newDates);
    },
  },

  methods: {
    getFormattedDate(date, format) {
      return moment(date, format).format(this.filteredAttrs.format || 'L');
    },

    applyValue() {
      this.$refs.datepicker.selectDate();

      this.$refs.datepicker.closeMenu();

      // Use the start date as end date when user do not select an end date
      if (!this.innerValue[1]) {
        this.innerValue[1] = this.innerValue[0];
      }

      this.$emit("apply", this.innerValue);
    },

    changeValue(value) {
      if (!value) {
        return;
      }

      this.innerValue = value.map(date => {
        return moment(date).format(this.parentFormat);
      });

      this.$emit("change", value);
    },

    cancel() {
      this.$refs.datepicker.closeMenu();
    },

    onOpen() {
      this.isOpen = true;
      this.$emit("open");
    },

    onClose() {
      this.isOpen = false;
      this.$emit("close");
    },

    updateManualDateInput(dates) {
      this.startDateText = moment(dates[0], this.parentFormat).format('LL');
      this.endDateText = dates[1] ? moment(dates[1], this.parentFormat).format('LL') : 'To';
    },
  },

  mounted() {
    this.innerValue = this.startDate;
  },
};
</script>

<style>
.dp-range--loading,
.dp-range--loading .dp__input_wrap input {
  @apply cursor-wait text-graphite-700 bg-graphite-200;
}

.dp-range--loading .dp__input_wrap input:hover,
.dp-range--loading .dp__input_wrap input:focus {
  @apply border-graphite-500 !important;
}

.dp-range .dp-range__input:focus,
.dp-range .dp-range__input.form-input--focus {
  @apply border-purple-500 !important;
}

.dp-range-menu .dp__action_row {
  @apply border border-solid border-graphite-400 p-3 flex justify-between;
}

.dp-range-menu .dp__action_buttons {
  @apply w-full;
}

.dp-range-menu .dp__date_hover:hover,
.dp-range-menu .dp__month_year_select:hover,
.dp-range-menu .dp__overlay_cell:hover {
  @apply bg-active-100;
}

.dp-range-menu .dp-custom-month-year-wrapper {
  @apply mb-5;
}

.dp-range-menu .dp__calendar_header {
  @apply mb-2;
}

.dp-range-menu .dp__calendar_row {
  @apply m-0;
}

.dp-range-menu .dp__calendar_header_separator {
  @apply hidden;
}

.dp-range-menu .dp__calendar_header_item {
  @apply text-sm font-inter font-medium text-purple-350 h-6 w-8 lowercase invisible;
}

.dp-range-menu .dp__calendar_header_item::first-letter {
  @apply visible;
}

.dp-range-menu .dp__cell_offset {
  @apply opacity-25;
}

.dp-range-menu .dp__cell_inner {
  border-radius: 1px;

  @apply border-0 h-6 w-8 text-black text-3xs font-inter;
}

.dp-range-menu .dp__cell_inner:hover:not(.dp__cell_offset) {
  @apply bg-purple-500 text-white border-purple-500;
}

.dp-range-menu .dp__range_end,
.dp-range-menu .dp__range_start,
.dp-range-menu .dp__active_date {
  @apply bg-purple-350 text-white !important;
}

.dp-range-menu .dp__range_between {
  @apply text-purple-350 bg-purple-100 rounded-none;
}

.dp-range-menu .dp__today {
  @apply border-purple-350 text-purple-350 border-solid border-2;
}

.dp-range-menu .dp__cell_disabled:not(.dp__cell_offset) {
  @apply text-graphite-700 bg-graphite-200;
}

.dp-range-menu .dp__flex_display {
  @apply p-4;
}

.dp-range-menu .dp__flex_display .dp__instance_calendar:first-child {
  @apply mr-3;
}

.dp-range-menu .dp__flex_display .dp__instance_calendar:last-child {
  @apply ml-3;
}

.dp-range-menu .dp__preset_ranges {
  @apply p-4 w-48;
}

.dp-range-menu .dp__preset_range {
  @apply px-4 py-2 text-sm;
}

.dp-range-menu .dp__preset_range:hover {
  @apply bg-purple-100;
}

/* Custom buttons */
.dp-range-menu .btn-cancel {
  @apply mr-3 border-purple-350 text-purple-350 !important;
}

.dp-range-menu .btn-cancel:hover {
  @apply opacity-75 !important;
}

.dp-range-menu .btn-apply {
  @apply border-purple-350 bg-purple-350 text-white !important;
}

.dp-range-menu .btn-apply:hover {
  @apply opacity-75 !important;
}

.dp-range-menu .btn-apply:disabled {
  @apply border-graphite-300 bg-graphite-300 text-graphite-700;
}

.dp-range-menu .dp__instance_calendar .dp__instance_calendar {
  padding-bottom: calc(16px + 16px + 40px); /* top space + bottom space + input size */
}

/* We are calculating the space for manual dates input */
.dp-range-menu .dp__selection_preview {
  left: 12rem; /* preset range space */
  bottom: 80px; /* padding from bottom */
  width: calc(100% - 12rem - 16px - 16px); /* full width - preset range space - left padding - rigth padding  */

  @apply absolute h-10 mx-4;
}

.dp-range-menu .manual-date {
  @apply flex;
}

.dp-range-menu .manual-date .text-input:first-child {
  @apply mr-3 relative;
}

/* dash between inputs */
.dp-range-menu .manual-date .text-input:first-child::after {
  content: '';
  top: 20px;

  @apply border border-solid border-graphite-600 -right-2 w-2 h-auto absolute;
}

.dp-range-menu .manual-date .text-input:last-child {
  @apply ml-3;
}

.dp-range-menu .manual-date .manual-date__input input:focus {
  @apply border-purple-500 !important;
}

.dp-range-menu .manual-date .manual-date__input.manual-date__input--no-date input {
  @apply text-graphite-700;
}
</style>
